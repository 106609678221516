import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from "../app/services/auth.service";
import { Router } from "@angular/router";

@Injectable()
export class AdminAuthGuard  {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (!this.authService.isAdminLoggedIn()) {
      localStorage.removeItem('admin_token');
      this.router.navigate(['/admin/login'])
      return false;
    }
    return true;
  }


}