import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../../../../services/admin.service'
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from '../../../../services/global.service'
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService, SharedModule} from 'primeng/api';
import { debounce } from 'lodash';
@Component({
  selector: 'app-lab-list',
  templateUrl: './lab-list.component.html',
  styleUrls: ['./lab-list.component.scss']
})
export class LabListComponent implements OnInit {

  labsList: any[] = []
  isLoading = false
  searchString:any = null
  offset:any = 0
  limit:any = 10
  nextOffset:any = null
  order:any[] = []
  constructor(private adminService: AdminService, private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.getLabs()
  }

  getLabs() {
    let params = {
      offset: this.offset,
      limit: this.limit,
      search: this.searchString,
      order: this.order
    }

    this.adminService.getLabs(params).subscribe((response:any) => {
      if (response.success) {
        if (response.next_offset != 0) {
          this.labsList = this.labsList.concat(response.labs)
        }
        this.nextOffset = response.next_offset
      }

    }, (err:any) => {
      console.error(err)
    })
  }

  sort = debounce((model, param, order) => {
    if (model)
      this.order = [[model, param, order]]
    else
      this.order = [[param, order]]

    this.labsList = []
    this.offset = null
    this.nextOffset = null
    this.getLabs()
  }, 300)


  loadLabs(e:any) {
    let browserZoomLevel = window.devicePixelRatio;
    let scrollHeight = e.target.scrollHeight;
    let scrollHeightZoom = scrollHeight * browserZoomLevel;

    if (e.target.offsetHeight + e.target.scrollTop === scrollHeightZoom || e.target.offsetHeight + e.target.scrollTop > scrollHeightZoom || e.target.offsetHeight + e.target.scrollTop == scrollHeight) {
      this.offset = this.offset + this.limit
      if (this.nextOffset !== 0) {
        this.getLabs()
      }
    }
  }

  delete(lab:any) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to Delete this pathlab from the system?',
      header: 'Confirmation',
      icon: 'fa fa-question-circle',
      accept: () => {
        this.removeLab(lab)
      },
      reject: () => {

      }
    })
  }

  searchLab() {
    this.labsList = []
    this.offset = null
    this.limit = 10
    this.nextOffset = null
    this.getLabs()
  }

  closeSearch() {
    this.searchString = null
    this.offset = null
    this.limit = 10
    this.nextOffset = null
    this.labsList = []
    this.getLabs()
  }

  removeLab(lab:any) {
    lab['status'] = 0
    let params = { lab: lab }
    this.adminService.updateLabs(params).subscribe((respose:any) => {
      if (respose.success) {
        this.searchString = null
        this.offset = null
        this.limit = 10
        this.nextOffset = null
        this.labsList = []
        this.getLabs()
      } else {
        // this.toastr.error(respose.error)
      }
    })
  }
}
