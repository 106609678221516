import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'; 
import { Router } from '@angular/router'
import { Constants } from "../../config/constants";

@Injectable({
  providedIn: 'root'
})
export class MarketPlaceService {

  constructor(private http: HttpClient, private router: Router) { }

  APIURL = `${Constants.APICONFIG.PROTOCOL}://${Constants.APICONFIG.HOST}:${Constants.APICONFIG.PORT}/v1`

  public getAllMasterProductVariations(): Observable<any> {
    return this.http.get(`${this.APIURL}/getAllMasterProductVariations`)
  }

  public getAllOrders(req_vars:any): Observable<any> {
    return this.http.post(`${this.APIURL}/getOrders`,req_vars)
  }

  public getProductVariationwithId(req_vars:any): Observable<any>{
    return this.http.post(`${this.APIURL}/getProductVariationwithId`,req_vars)
  }

  public saveOrderByUserid(req_vars:any):Observable<any>{
    return this.http.post(`${this.APIURL}/saveOrderByUserid`,req_vars)
  }

  public getOrderWithId(req_vars:any) : Observable<any>{
    return this.http.post(`${this.APIURL}/getOrderWithId`,req_vars)
  }

  public updateProductVariationQuantity(req_vars:any) : Observable<any>{
    return this.http.post(`${this.APIURL}/updateProductVariationQuantity`,req_vars)
  }

  public deleteProductVariation(req_vars:any) : Observable<any>{
    return this.http.post(`${this.APIURL}/deleteProductVariation`,req_vars)
  }

  public getOrdersOfUser(req_vars:any) : Observable<any>{
    return this.http.post(`${this.APIURL}/getOrdersOfUser`,req_vars)
  }

}
