import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../../../../services/admin.service'
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from '../../../../services/global.service'
import { debounce } from 'lodash';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService, SharedModule} from 'primeng/api';
import { AuthService } from '../../../../services/auth.service'
@Component({
  selector: 'app-doctor-list',
  templateUrl: './doctor-list.component.html',
  styleUrls: ['./doctor-list.component.scss']
})
export class DoctorListComponent implements OnInit {
  userList: any = []
  search: any
  totalUsers: number
  offset: any
  limit: number
  nextOffset:any = null
  totalPages: number[]
  currentPage: number
  isLoading: Boolean = false
  degreeArray: any = []
  councilsArray: any = []
  searchString:any = null
  totalRecords = 0
  userId:any = null
  order:any[] = []

  constructor(private adminService: AdminService, private authService: AuthService, private confirmationService: ConfirmationService, private router: Router, private toastr: ToastrService, private globalService: GlobalService) {
    this.search = {}
    this.userId = authService.getAdminUser()
    this.totalUsers = this.offset = 0
    this.limit = 10
    this.currentPage = 1
    this.totalPages = [1]
  }

  ngOnInit() {
    let token = localStorage.getItem('admin_token')
    if (token) {
      this.getUsers();
      this.getConstants();
    } else {
      this.router.navigate(['/admin/login'])
    }
  }

  loadDoctors(e:any) {
    let browserZoomLevel = window.devicePixelRatio;
    let scrollHeight = e.target.scrollHeight;
    let scrollHeightZoom = scrollHeight * browserZoomLevel;

    if (e.target.offsetHeight + e.target.scrollTop === scrollHeightZoom || e.target.offsetHeight + e.target.scrollTop > scrollHeightZoom || e.target.offsetHeight + e.target.scrollTop == scrollHeight) {
      this.offset = this.offset + this.limit
      if (this.nextOffset !== 0) {
        this.getUsers()
      }
    }
  }

  getUsers = (query:any = {}, search = false) => {
    this.isLoading = false
    query['user_type'] = "doctor"
    let req_vars = {
      query: query,
      search: this.searchString,
      offset: this.offset,
      limit: this.limit,
      order: this.order
    }
    this.adminService.usersList(req_vars).subscribe((response:any) => {

      if (response.success) {
        if (response.next_offset != 0) {
          this.userList = this.userList.concat(response.users)
        }
        this.totalRecords = response.total
        this.nextOffset = response.next_offset

      }
    }, (err:any) => {

    })
  }

  searchDoctors = debounce(() => {
    this.userList = []
    this.offset = null
    this.limit = 10
    this.nextOffset = null
    this.getUsers()
  }, 300)

  closeSearch() {
    this.searchString = null
    this.offset = null
    this.limit = 10
    this.nextOffset = null
    this.userList = []
    this.getUsers()
  }

  getConstants = () => {
    this.adminService.getConstants().subscribe((response:any) => {
      this.degreeArray = response.degrees
      this.councilsArray = response.state_medical_councils
    })
  }

  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  updateRecord(id:any, status: string) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to activate this user?',
      header: 'Confirmation',
      icon: 'fa fa-question-circle',
      acceptLabel: 'Ok',
      rejectLabel: 'Cancel',
      accept: () => {
        const req_vars = {
          user_id: id,
          status: status,
          admin_id: this.userId
        }
        this.adminService.deactivate(req_vars).subscribe((response:any) => {
          if (response) {
            this.toastr.success("User updated successfully.")
            this.userList = []
            this.offset = null
            // this.limit = 10
            this.nextOffset = null
            this.getUsers()
          } else {
            this.toastr.error("something went wrong.")
          }
        })
      },
      reject: () => {

      }
    })
  }

  sort = debounce((model, param, order) => {
    if (model)
      this.order = [[model, param, order]]
    else
      this.order = [[param, order]]

    this.userList = []
    this.offset = null
    this.nextOffset = null
    this.getUsers()
  }, 300)


  deactivate(id:any, status: string) {

    this.confirmationService.confirm({
      message: 'Are you sure you want to deactivate this user?',
      header: 'Confirmation',
      icon: 'fa fa-question-circle',
      acceptLabel: 'Ok',
      rejectLabel: 'Cancel',
      accept: () => {
        const req_vars = {
          user_id: id,
          status: status,
          admin_id: this.userId
        }
        this.adminService.deactivate(req_vars).subscribe((response:any) => {
          if (response) {
            this.toastr.success("User updated successfully.")
            this.userList = []
            this.offset = null
            // this.limit = 10
            this.nextOffset = null
            this.getUsers()
          } else {
            this.toastr.error("something went wrong.")
          }
        })
      },
      reject: () => {

      }
    })
  }

}
