import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service'
import { AdminService } from '../../services/admin.service'
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from '../../services/global.service'
import { UtilService } from '../../util/utilService'

import { debounce } from 'lodash';
@Component({
  selector: 'app-otps',
  templateUrl: './otps.component.html',
  styleUrls: ['./otps.component.scss']
})
export class OtpsComponent implements OnInit {
  otpList: any = []
  search: any
  totalUsers: number
  offset: any;
  limit: number
  nextOffset:any = null
  totalPages: number[]
  currentPage: number
  isLoading: Boolean = false
  degreeArray: any = []
  councilsArray: any = []
  totalRecords:any = null
  searchString:any = null
  searchByGender:any = null
  fromDate:any = null
  toDate:any = null
  order:any[] = []
  searchByType:any = null

  constructor(private util: UtilService, private adminService: AdminService, private router: Router, private toastr: ToastrService, private globalService: GlobalService) {
    this.search = {}
    this.totalUsers = this.offset = 0
    this.limit = 10
    this.currentPage = 1
    this.totalPages = [1]
  }

  ngOnInit() {

    this.getOTPs();
    this.setDefaultDates()
  }

  setDefaultDates() {
    let today = new Date()
    this.toDate = this.util.reverseDate(today)
    let pastDate = new Date(today.setMonth(today.getMonth() - 6))
    this.fromDate = this.util.reverseDate(pastDate)
  }

  loadActivities(e:any) {
    let browserZoomLevel = window.devicePixelRatio;
    let scrollHeight = e.target.scrollHeight;
    let scrollHeightZoom = scrollHeight * browserZoomLevel;

    if (e.target.offsetHeight + e.target.scrollTop === scrollHeightZoom || e.target.offsetHeight + e.target.scrollTop > scrollHeightZoom || e.target.offsetHeight + e.target.scrollTop == scrollHeight) {
      this.offset = this.offset + this.limit
      if (this.nextOffset !== 0) {
        this.getOTPs()
      }
    }
  }

  searchActivities = debounce(() => {
    this.otpList = []
    this.offset = null
    this.nextOffset = null
    if (this.searchString) {
      this.search.search_string = this.searchString
    } else {
      delete this.search['search_string']
    }
    if (this.searchByType) {
      if (this.searchByType == 'all') {
        delete this.search['search_by_type']
      } else {
        this.search.search_by_type = this.searchByType
      }

    }
    this.search.from_date = this.fromDate ? this.util.parseDate(this.fromDate) : null
    this.search.to_date = this.toDate ? this.util.parseDate(this.toDate) : null



    this.getOTPs()
  }, 300)

  sort = debounce((model, param, order) => {
    if (model)
      this.order = [[model, param, order]]
    else
      this.order = [[param, order]]

    this.offset = null
    this.nextOffset = null
    this.getOTPs()
  }, 300)


  clearFilters() {
    this.search = {}
    this.searchString = null
    this.searchByGender = null
    this.fromDate = null
    this.toDate = null
    this.searchByType = null
    this.setDefaultDates()
    this.getOTPs()
  }


  closeCalender(e:any, d:any) {

    if (d._elRef.nativeElement.parentElement.contains(e.target)) {

    } else {
      d.close()
    }
    if (e.target.offsetParent == null)
      d.close()
    else if (e.target.offsetParent && e.target.offsetParent.nodeName != "NGB-DATEPICKER" && (e.target.offsetParent.className.indexOf('calender') == -1 && e.target.offsetParent.className.indexOf('custom-select') == -1))
      d.close()
  }

  getOTPs = () => {
    this.isLoading = true;

    this.globalService.getOTPs().subscribe((response:any) => {
      if (response) {
        this.otpList = response;
        this.isLoading = false;
      }
    }, (err:any) => {
      console.error(err);
      this.isLoading = false;

    })
  }


  sendAashaMail() {
    this.globalService.sendAashaMail().subscribe((response:any) => {
      if (response.success) {

        this.toastr.success("Aasha report sent successfully")

      }
    }, (err:any) => {
      console.error(err)
    })
  }

  sendDoctorMail() {
    this.globalService.sendDoctorMail().subscribe((response:any) => {
      if (response.success) {

        this.toastr.success("Doctor report sent successfully")

      }
    }, (err:any) => {
      console.error(err)
    })
  }

  getAge(dob:any) {
    dob = new Date(dob)
    let age = this.util.calculateAge(dob)
    let ageStr = null
    ageStr = age['years'] ? `${age['years']}Y` : null
    ageStr = ageStr ? age['months'] ? `${ageStr}, ${age['months']}M` : `${ageStr}` : 0
    return ageStr
  }


  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }




}
