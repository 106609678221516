<!--Basic info thank you section(start here) -->
<div style="margin: 12px;">
  <div class="main-content-loader d-flex justify-content-center align-items-center position-fixed" *ngIf="showLoading">
    <label for="" class="loader"></label>
  </div>

  <div class="clinician-detailed-wrapper-outer pl-1" *ngIf="!showLetterhead">
    <div class="row clinician-detailed-wrapper">
      <div class="col-12 clearfix grayBottomBorder mb-3 pb-3">
        <div class="profileDetailedWrapper">
          <div class="profileDetailedTopSection">
            <span class="headingUpprcase18">View Doctor Profile</span>
            <a class="right editprofileDetailed" routerLink="/admin/users/doctors/profile/{{userId}}"><i
                placement="left" ngbTooltip="Edit" class="fa fa-pencil-square-o" aria-hidden="true"></i></a>
          </div>
          <ul class="breadcrumb breadcrumb-page admin-breadcrumb-page">
            <div class="breadcrumb-label text-light-gray">You are here: </div>
            <div class="breadcrumbs admin-breadcrumbs">
              <a routerLink="/admin/users/doctors">Doctor Management</a> » <span>View Profile</span>
            </div><!-- breadcrumbs -->
          </ul>

        </div>
        <div class="row">
          <div class="col-6">
            <div class="clinicianDetailed">
              <p class="ifnoDetail">Dr. {{user.first_name}} {{user.last_name}}</p>
              <p class="ifnoLabel capitalize">
                <span *ngIf="user.gender != 'do_not_disclose'">{{user.gender}},</span>
                <span *ngIf="user.gender == 'do_not_disclose'">Non-specified,</span>
                {{user.age}} years</p>
            </div>
          </div>
        </div>
        <div class="row ">
          <div class="col-6">
            <div class="clinicianDetailed">
              <p class="ifnoDetail">Mobile Number</p>
              <p class="ifnoLabel">{{user.phone_number}}</p>
            </div>
          </div>
          <div class="col-6">
            <div class="clinicianDetailed">
              <p class="ifnoDetail">Email Address</p>
              <p class="ifnoLabel">{{user.email_id}}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="clinicianDetailed">
              <p class="ifnoDetail">Medical Registration</p>
              <p class="ifnoLabel">{{user.doctor.registration_number}}
                {{councilsArray[user.doctor.state_medical_council]}}, {{user.doctor.year_of_registration}}</p>
            </div>
          </div>
          <div class="col-6">
            <div class="clinicianDetailed">
              <p class="ifnoDetail">Experience</p>
              <p class="ifnoLabel">{{user.doctor.experience ? user.doctor.experience + ' Years'  : '-'}}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p class="ifnoDetail m-0">Bio / Summary</p>
            <p class="ifnoLabel mb-3 line-breaker">{{user.doctor.bio ? user.doctor.bio : '-'}}</p>
          </div>
        </div>
      </div>
      <div class="col-12 clearfix grayBottomBorder mb-3 pb-3">
        <div class="profileDetailedWrapper">
          <div class="profileDetailedTopSection mt-4">
            <span class="headingUpprcase18">Pracitice Clinics</span>
            <a class="right editprofileDetailed" *ngIf="!readOnly"
              routerLink="/admin/users/doctors/clinics/{{userId}}"><i placement="left" ngbTooltip="Edit"
                class="fa fa-pencil-square-o" aria-hidden="true"></i></a>
          </div>
        </div>
        <div class="row" *ngFor="let clinic of clinics; let i = index" [ngClass]="{'mt-5': i != 0}">
          <div class="col-6">
            <div class="clinicianDetailed">
              <p class="ifnoDetail capitalize">{{clinic.name}}</p>
              <p class="ifnoLabel" *ngIf="clinic.multispeciality">(Multispeciality)</p>

              <p class="ifnoLabel mt-2">
                <span class="capitalize" *ngIf="clinic.address_line1">{{clinic.address_line1}},</span>
                <span class="capitalize" *ngIf="clinic.address_line2">{{clinic.address_line2}}, </span>
                <br />
                <span class="capitalize" *ngIf="clinic.address_line3">{{clinic.address_line3}}, </span>
                <span class="capitalize" *ngIf="clinic.pincode">{{clinic.pincode}} </span>
                <br />
                <span *ngIf="clinic.ClinicContacts.length > 0">Phone:</span>
                <span *ngFor="let contact of clinic.ClinicContacts; let i=index">
                  {{contact.phone}}<span *ngIf="i != clinic.ClinicContacts.length - 1">, </span>
                </span>
                <br />
                <span *ngIf="clinic.ClinicContacts.length > 0">Email:</span>
                <span *ngFor="let contact of clinic.ClinicContacts; let i=index">
                  {{contact.email}}<span *ngIf="i != clinic.ClinicContacts.length - 1">, </span>
                </span>
                <br />
              </p>

              <p class="ifnoDetail mt-2">Type of Service/ Facility:</p>
              <p class="ifnoLabel">
                <span *ngFor="let facility of clinic.ClinicFacilities;let i = index">
                  <span *ngIf="i == 0">{{facility.service_type}}</span>
                  <span *ngIf="i > 0">, {{facility.service_type}}</span>
                </span>
              </p>
              <div class="row">
                <div class="col-12">
                  <p class="ifnoDetail mt-2">Consultant:</p>
                </div>
                <div class="col-6" *ngFor="let consultant of clinic.ClinicConsultants">
                  <p class="ifnoLabel capitalize">{{consultant.first_name}} {{consultant.last_name}}</p>
                  <p class="ifnoLabel" *ngIf="consultant.phone">Phone: {{consultant.phone}}</p>
                  <p class="ifnoLabel" *ngIf="consultant.email">Email: {{consultant.email}}</p>
                </div>

              </div>

            </div>
          </div>
          <div class="col-6">
            <div class="clinicianDetailed" *ngFor="let session of clinic.groupedSessions">
              <p class="ifnoDetail">

                <span *ngIf="session.value.days.length > 0">
                  <span *ngFor="let day of session.value.days; let i = index">
                    <span *ngIf="i == 0 && day.day != null" class="capitalize">{{day.day}}</span>
                    <span *ngIf="i != 0 && day.day != null" class="capitalize">/ {{day.day}}</span>
                  </span>
                </span>
              </p>
              <p class="ifnoLabel">
                {{session.time}}
              </p>

            </div>
            <div class="clinicianDetailed" *ngIf="clinic.groupedOnCalls.length > 0">
              <p class="ifnoDetail">

                <span *ngIf="clinic.groupedOnCalls.length > 0">
                  <span *ngFor="let day of clinic.groupedOnCalls; let i = index">
                    <span *ngIf="i == 0 && day != null" class="capitalize">{{day}}</span>
                    <span *ngIf="i != 0 && day != null" class="capitalize">/ {{day}}</span>
                  </span>
                </span>
              </p>
              <p class="ifnoLabel">
                On call
              </p>
            </div>


          </div>
        </div>

      </div>
      <div class="col-12 clearfix grayBottomBorder mb-3 pb-3">
        <div class="profileDetailedWrapper">
          <div class="profileDetailedTopSection mt-4">
            <span class="headingUpprcase18">Education</span>
            <a class="right editprofileDetailed" *ngIf="!readOnly"
              routerLink="/admin/users/doctors/profile/{{userId}}"><i placement="left" ngbTooltip="Edit"
                class="fa fa-pencil-square-o" aria-hidden="true"></i></a>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="clinicianDetailed" *ngFor="let education of user.doctor.educations; let i = index">
              <p class="ifnoDetail">{{specializations[education.speciality]}}</p>
              <p class="ifnoLabel">{{institutes[education.institute]}}, {{education.year}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 clearfix grayBottomBorder mb-3 pb-3">
        <div class="profileDetailedWrapper">
          <div class="profileDetailedTopSection mt-4">
            <span class="headingUpprcase18">SPECIALIZATION </span>
            <a class="right editprofileDetailed" *ngIf="!readOnly"
              routerLink="/admin/users/doctors/profile/{{userId}}"><i placement="left" ngbTooltip="Edit"
                class="fa fa-pencil-square-o" aria-hidden="true"></i></a>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="clinicianDetailed">
              <p class="ifnoLabel">{{specializations[user.doctor.letterhead_specialization]}}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-12 clearfix grayBottomBorder mb-3 pb-3">
        <div class="profileDetailedWrapper">
          <div class="profileDetailedTopSection mt-4">
            <span class="headingUpprcase18">AWARDS & RECOGNITION </span>
            <a class="right editprofileDetailed" *ngIf="!readOnly"
              routerLink="/admin/users/doctors/profile/{{userId}}"><i placement="left" ngbTooltip="Edit"
                class="fa fa-pencil-square-o" aria-hidden="true"></i></a>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="clinicianDetailed">
              <p class="ifnoLabel mb-2" *ngFor="let award of user.doctor.awards">
                {{award.title}} - {{award.year}}
              </p>
            </div>
          </div>
        </div>
      </div> -->
      <div class="col-12 clearfix grayBottomBorder mb-3 pb-3" style="background-color: azure;">
        <div class="profileDetailedWrapper">
          <div class="profileDetailedTopSection mt-4">
            <span class="headingUpprcase18">DETAILS ABOUT MORE SERVICE</span>
            <a class="right editprofileDetailed" *ngIf="!readOnly"
              routerLink="/admin/users/doctors/profile/{{userId}}"><i placement="left" ngbTooltip="Edit"
                class="fa fa-pencil-square-o" aria-hidden="true"></i></a>
          </div>
        </div>
        <div class="row" style="background-color: azure;">
          <div class="col-12">
            <div class="clinicianDetailed">
              <p class="ifnoLabel mb-2  line-breaker">{{user.doctor.services}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 clearfix mb-3">
        <div class="profileDetailedWrapper">
          <div class="profileDetailedTopSection mt-4">
            <span class="headingUpprcase18">Letterhead</span>
            <a class="right editprofileDetailed" *ngIf="!readOnly"
              routerLink="/admin/users/doctors/letterhead/{{userId}}"><i placement="left" ngbTooltip="Edit"
                class="fa fa-pencil-square-o" aria-hidden="true"></i></a>
          </div>
        </div>
        <div class="row" *ngIf="!readOnly">
          <div class="col-12">
            <div class="clinicianDetailed">
              <p class="ifnoLabel">
                <a (click)="previewLetterhead()">
                  (Click to preview)
                </a>
              </p>
              <!--<img src="assets/images/letterHead-preView.png" alt="" />-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showLetterhead">
    <div class="clinician-detailed-wrapper-outer">
      <div class="row">
        <div class="col-12">
          <label class="ifnoLabel upprcase ifnoDetail mb-3">Letterhead</label>
        </div>
      </div>
      <table style="border:1px solid; width:530px">
        <tr>
          <td>
            <table style="width:100%;">
              <tr>
                <td style="padding:15px 5px 5px 15px;width:320px;font-size:17px;font-weight:500px">
                  <strong>{{primaryClinic && primaryClinic.name}}</strong></td>
                <td style="padding:15px 5px 5px 15px;font-size:17px;font-weight:500px"><strong>Dr. {{user.first_name}}
                    {{user.last_name}}</strong></td>
              </tr>
              <tr>
                <td style="padding:0px 5px 0px 15px;border-right:1px solid;font-size:12px"><span
                    *ngIf="primaryClinic">{{primaryClinic.address_line1}} {{primaryClinic.address_line2}}
                    {{primaryClinic.address_line3}} {{primaryClinic.city}} {{primaryClinic.pincode}}</span></td>
                <td style="padding:0px 5px 0px 15px;font-size:12px">{{degreeArray[user.doctor.degree]}}</td>
              </tr>
              <tr>
                <td style="padding:0px 10px 0px 15px;width:300px;font-size:11px"><strong>For Appointment:</strong>
                  <span *ngFor="let contact of primaryClinic && primaryClinic.ClinicContacts; let i = index">
                    {{contact.phone}}<span *ngIf="i != primaryClinic.ClinicContacts.length - 1">, </span>
                  </span>
                </td>
                <td style="padding:0px 10px 0px 15px;font-size:11px">Regn. No. {{user.doctor.registration_number}}</td>
              </tr>
              <!-- <tr> 
                          <td style="padding:5px 10px 0px 15px;font-size:11px"><strong>Timings:</strong> 12:00 AM to 11:59 AM & 12:00 PM to 11:59 PM</td> 
                        </tr> -->
              <tr>
                <td style="padding:15px 15px 0px 15px;" colspan="2">
                  <table width="100%" style="padding:0;border-top: 1px solid;">
                    <tr>
                      <td style="padding:10px 0 0 0;font-size:11px;width:65.5%;"><strong>Prescription No.:</strong>
                        PGC/Rx/022016/10970</td>
                      <td style="padding:10px 10px 0px 0;font-size:12px"><strong>Date:</strong> 17/02/2016</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td style="padding:11px 15px 0px 15px;">
            <table style="padding:0;border: 1px solid;">
              <tr>
                <td style="padding:5px 10px 0px 15px;font-size:13px"><strong>Patient’s Name:</strong>Netra Sarjerao Mali
                  (Female/10 Y, 8 M, 24 D)</td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tr>
                      <td style="padding:5px 10px 0px 15px;font-size:13px"><strong>M:</strong> 17</td>
                      <td style="padding:5px 10px 0px 120px;font-size:13px"><strong>Height (CM):</strong> 17</td>
                      <td style="padding:5px 10px 0px 100px;font-size:13px"><strong>Weight: </strong> 17</td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td style="padding:5px 10px 0px 15px;font-size:13px"><strong>Chief Complaint:</strong> Cold, Cough,
                  Cold, Cough, Cold, Cough</td>
              </tr>
              <tr>
                <td style="padding:5px 10px 10px 15px;font-size:13px"><strong>On Examination:</strong> Chest, Throat:
                  PND, Ears: Normal, Nose+, No-Lad, Jdj small in insig</td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table>
              <tr>
                <td colspan="2" style="padding: 10px 15px;">
                  Vaccination <strong>Hep-B (Dose 1/3)</strong> administered today.
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <table>
                    <tr>
                      <td style="padding: 10px 0 10px 15px;vertical-align: text-bottom;width: 30%;">Vaccination details:
                      </td>
                      <td style="padding: 10px 15px 10px 0px;">Protects child against the hepatitis B virus, Which can
                        lead
                        to liver damage.
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td style="padding:15px 10px 0px 15px;font-size:13px"><strong>Diagnosis:</strong> UTRI</td>
                <td></td>
              </tr>
              <tr>
                <td style="padding:15px 10px 0px 15px;font-size:13px">
                  <strong>Erithromycin</strong> 10 mg | 1/2 | 2 times | 5 days | After food<br />
                  Consult for continuation
                </td>
              </tr>
              <tr>
                <td style="padding:15px 10px 0px 15px;font-size:13px">
                  <strong>Crocin</strong> 1/2 spoon | 1 times | 3 days | After food<br />
                  Consult for continuation
                </td>
              </tr>
              <tr>
                <td style="padding:15px 10px 0px 15px;font-size:13px">
                  <strong>Erithromycin</strong> 10 mg | 1/2 | 2 times | 5 days | After food<br />
                  Consult for continuation
                </td>
              </tr>
              <tr>
                <td style="padding:15px 10px 0px 15px;font-size:13px">
                  <strong>Crocin</strong> 1/2 spoon | 1 times | 3 days | After food<br />
                  Consult for continuation
                </td>
              </tr>
              <tr>
                <td style="padding:15px 10px 0px 15px;font-size:13px">
                  Next vaccination for <strong>DTwP/DTaP (Dose 1/3)</strong> is due on <strong>14 June 2019</strong>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table style="margin-left:15px;border-bottom:1px solid">
              <tr>
                <td style="padding:40px 10px 0px 0px;font-size:12px"><strong>General Instructions:</strong>
                  <p style="margin-bottom:0px">1. Marathi Fonts, 2. Stream inhalation at night</p>
                </td>

              </tr>
              <tr>
                <td style="padding:5px 10px 0px 0px;font-size:12px"><strong>Next Appointment:</strong> -</td>
                <td style="padding:5px 10px 0px 140px;font-size:12px">Dispensed by<br><strong
                    style="margin-left:-22px">Dr. Rohan Kadam</strong></td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table>
              <tr>
                <td *ngFor="let clinic of letterheadClinics"
                  style="padding:5px 5px 15px 15px;font-size:12px;width:250px">
                  <strong>{{clinic.name}}</strong><br>
                  <span>- {{clinic.address_line1}} {{clinic.address_line2}} {{clinic.address_line3}} {{clinic.city}}
                    {{clinic.pincode}}</span>
                </td>

              </tr>
            </table>
          </td>
        </tr>
      </table>

      <div class="center buttons">
        <button class="btn btn-secondary opd-primary-btn transformNormal btn-lg upprcase"
          (click)="showLetterhead = false">Go back</button>
      </div>
    </div>
  </div>
</div>