import { Injectable } from '@angular/core'

@Injectable()
export class StorageService {

  constructor() {}

  setStage(stage: string) {
    localStorage.setItem('stage', stage);
  }

  setData(data: any) {
    localStorage.setItem('data', data);
  }

  getStage() {
    return localStorage.getItem('stage');
  }
  getData() {
    const data = localStorage.getItem('data') ?? '{}'; // Default to an empty object string
    return JSON.parse(data);
  }

  removeStage() {
    return localStorage.removeItem('stage');
  }

  removeData() {
    return localStorage.removeItem('data');
  }

  getDoctor() {
    const doctorData = localStorage.getItem('doctor') ?? '{}'; // Default to an empty object string
    return JSON.parse(doctorData);
  }

  removeDoctor() {
    return localStorage.removeItem('doctor');
  }

  setDoctor(data: any) {
    localStorage.setItem('doctor', data);
  }

}

