import { Component, OnInit, Input } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';
import { refreshService } from '../../services/refersh.service';
import { DoctorService } from '../../services/doctor.service';
import moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-doc-rpt-dash',
  templateUrl: './doc-rpt-dash.component.html',
  styleUrls: ['./doc-rpt-dash.component.scss']
})
export class DocRptDashComponent implements OnInit {
  @Input() selfDoctor!: String;
  startDateAndEndDate:boolean=false
  recentlyDoctors: any;
  searchString:any;
  doctorStatCount: any = [];
  userID:any;
  patientCount:any;
  medicalConditionCount:any
  prescriptionCount:any;
  appointmentCount:any;
  requisitionsCount:any;
  reportCount:any;
  healthAppCustomers:any;
  healthAppPatients:any;
  totDocHealtAppInstaCount = 0;
  healthAppNotInstalledCustomers:any;
  totalPatPendingVaccine:any;
  totalPatTakenVaccine:any;
  totalSendNotifications:any;
  selecteddoctorsnames: any = '';
  mulSelectedDoctor: any = [];
  count_from_startdate:any;
  count_to_endtdate:any;
  closeResult = '';
  startDate_pass:any;
  checks = false;
  DoctorId: any
  endDate_pass:any;
  filterSalesPersonName: any = [];
  filterDoctorName: any = [];
  dateRangeWiseData = false;
  docCheckBox = false;
  selfDoctorStartDate:any;
  selfDoctorEndDate:any;
  doctorsList: any = [];
  labname: any = [];
  selctedDoctPatientsRecords: any = [];
  selctedDoctPatPrescriptionsRecords: any = [];
  selctedDoctPatAppointmentsRecords: any = [];
  selctedDoctPatRequisitionsRecords: any = [];
  selctedDoctPatLabReportsRecords: any = [];
  selctedDoctCostumersRecords: any = [];
  selctedDoctPatHealthNotInstalledRecords: any = [];
  selctedDoctPatGivaenVaccRecords: any = [];
  selctedDoctPatNotGivaenVaccRecords: any = [];
  selctedDoctPatPushNotificationsRecords: any = [];
  totalPendingVaccineForSevenDaysLength = 0;
  totalPendingVaccineForSevenDays: any = [];
  selectedCheckBox: any = [];
  doctors: any = [];
  fileName:any;

  ashaDoctors: any = [];
  labReportCount=0
  labDoctors: any = [];
  otherDoctors: any = [];
  reportCategories = [
    { id: 1, categoryName: "Clinic Doctors" },
    { id: 2, categoryName: "Asha Workers" },
    { id: 3, categoryName: "Labs" },
  ];
  salesPersons: any = [];

  doctorJoiningDate = '';

  constructor(
    private adminService: AdminService,
    private modalService: NgbModal,
    private doctorService: DoctorService,
    private router: Router
  ) { }

  ngOnInit(): void {
    console.log("selfDoctor", this.selfDoctor);
    this.getAllSalesPersons();
    this.allDoctorsList();
    this.clearCount();
    this.salesTeamRecords();
   
    var docuserData = JSON.parse(localStorage.getItem('docObj')?? 'null');

    if (this.selfDoctor == '1' && docuserData) {
      //get doc detials
      this.doctorService.getDetails({ user_id: docuserData.id }).subscribe((response:any) => {
        if (response.success) {
          this.DoctorId = response.doctor.id;

          this.doctorJoiningDate = response.doctor.createdAt ? moment(response.doctor.createdAt).format('DD-MM-YYYY') : 'NA';
          let getCurrentDate = new Date();
          const objCurrentDate = { year: getCurrentDate.getFullYear(), month: getCurrentDate.getMonth() + 1, day: getCurrentDate.getDate() };
          this.selfDoctorStartDate = this.convertDateFormtat(getCurrentDate);

          getCurrentDate.setDate(getCurrentDate.getDate() + 1);
          const objBeginDate = { year: getCurrentDate.getFullYear(), month: getCurrentDate.getMonth() + 1, day: getCurrentDate.getDate() };
          console.log(objBeginDate);
          let getNextDate = getCurrentDate.toString();

          this.selfDoctorEndDate = this.convertDateFormtat(getNextDate);

          this.dateRangeCount(this.selfDoctorStartDate, this.selfDoctorEndDate, false);
          this.count_to_endtdate = objBeginDate;
          this.count_from_startdate = objCurrentDate;
        }
      })
      this.doctorPatLabReportsRecords();

    }
  }

  getAllSalesPersons() {
    this.adminService.getAllSalesPersons({}).subscribe((response:any) => {
      if (response.success) {
        this.salesPersons = response.data;
        console.log('response.data', response.data)
      }
    })
  }

  convertDateFormtat(dateToCovert:any) {
    let date = new Date(dateToCovert),
      mnth = ("0" + (date.getMonth() + 1)).slice(0),
      day = ("0" + date.getDate()).slice(-2);
    return [([date.getFullYear(), mnth, day].join("-"))];
  }
  reportCategory(selectedDoctorCategory:any) {
    if (selectedDoctorCategory == "Clinic Doctors") {
      let j = 0;
      for (let i = 0; i < this.doctorsList.length; i++) {
        if (this.doctorsList[i].isAsha == "d" || this.doctorsList[i].isAsha == null) {
          this.doctors[j] = this.doctorsList[i];
          j++;
        }
      }
      this.recentlyDoctors = this.doctors;
      console.log(this.doctors);
      console.log(selectedDoctorCategory);
    } else if (selectedDoctorCategory == "Asha Workers") {
      let j = 0;
      for (let i = 0; i < this.doctorsList.length; i++) {
        if (this.doctorsList[i].isAsha == "y") {
          this.ashaDoctors[j] = this.doctorsList[i];
          j++;
        }
      }
      this.recentlyDoctors = this.ashaDoctors;
      console.log(this.ashaDoctors);
      console.log(selectedDoctorCategory);
    } else if (selectedDoctorCategory == "Labs") {
      let j = 0;
      for (let i = 0; i < this.doctorsList.length; i++) {
        if (this.doctorsList[i].isAsha == "l") {
          this.labDoctors[j] = this.doctorsList[i];
          j++;
        }
      }
      this.recentlyDoctors = this.labDoctors;
      console.log(this.labDoctors);
      console.log(selectedDoctorCategory);
    }
  }
  // To Get Doctor Stat Records Count
  docRecordCount(doctid:any) {
    console.log("docRecordCount fired");
    
    let params = {
      doctorids: doctid,
    }
    this.adminService.admingetDoctorsStats(params).subscribe((response:any) => {
      if (response) {
        console.log('Response Count', response);
        this.doctorStatCount = response;
        // this.patientCount = response.totalpatients[0].patcount;
        // console.log("this.patientCount 1",this.patientCount);
        
        
        this.medicalConditionCount=this.doctorStatCount.totalMedicalConditions[0].AffectedComment
        this.appointmentCount = this.doctorStatCount.totalAppointments[0].totalAppointments;
        this.prescriptionCount = this.doctorStatCount.totalPrescriptions[0].totalPrescriptions;
        this.requisitionsCount = this.doctorStatCount.totalRequisitions[0].totalRequisitions;
        this.reportCount = this.doctorStatCount.totalReports[0].totalReports;
        this.healthAppCustomers = this.doctorStatCount.totalHealthCustomers[0].totalHealthCustomers;
        this.healthAppPatients = this.doctorStatCount.totalHealthPatients[0].totalHealthPatients;
        this.totDocHealtAppInstaCount = this.doctorStatCount.totDocHealtAppInstaCount;
        this.totalPatTakenVaccine = this.doctorStatCount.totalTakenVaccine[0].totalTakenVaccine;
        this.totalPatPendingVaccine = this.doctorStatCount.totalPendingVaccine[0].totalPendingVaccine;
        this.healthAppNotInstalledCustomers = this.doctorStatCount.totalHealthNotInstalledCustomers[0].totalHealthNotInstalledCustomers;
        this.totalSendNotifications = this.doctorStatCount.totalSendNotifications[0].totalSendNotifications;
       console.log("NOV1",this.totalPatPendingVaccine)
      } else {
        console.log(response.error);
      }
    });
    this.getPendingVaccinesOn7thDay(doctid);
  }


  getPendingVaccinesOn7thDay(doctorids:any) {
    var docArr = [];
    for (let index = 0; index < doctorids.length; index++) {
      const element = doctorids[index];

      for (let j = 0; j < this.recentlyDoctors.length; j++) {
        const doc = this.recentlyDoctors[j];
        if (element === doc.id) {
          docArr.push({ id: doc.id, fullName: `${doc.first_name} ${doc.last_name}`, mobileNumber: doc.phone_number })
        }
      }
    }

    if (docArr.length) {

      let params: any = {
        doctorArr: docArr,
      }
      if (this.count_from_startdate) {
        if (this.count_from_startdate.month) {
          params.startDate = moment(this.count_from_startdate).subtract(1, 'month').format('YYYY-MM-DD');
        }
        else {
          params.startDate = moment(this.count_from_startdate).format('YYYY-MM-DD');
        }
      }
      if (this.count_to_endtdate) {
        if (this.count_to_endtdate.month) {
          params.endDate = moment(this.count_to_endtdate).subtract(1, 'month').format('YYYY-MM-DD');
        }
        else {
          params.endDate = moment(this.count_to_endtdate).format('YYYY-MM-DD');
        }
      }

      params.doctorids = [doctorids];
      this.adminService.getPendingVaccinesOn7thDay(params).subscribe((response:any) => {
        if (response) {
          this.totalPendingVaccineForSevenDays = response.totalPendingVaccineForSevenDays;
          this.totalPendingVaccineForSevenDaysLength = this.totalPendingVaccineForSevenDays.length
        }
      })
    }

  }

  // Function To Search Doctors In Doctors List
  searchDoctor() {
    this.docCheckBox = false
    if (this.searchString.length > 0) {
      var arr = [];
      for (let index = 0; index < this.recentlyDoctors.length; index++) {
        const element = this.recentlyDoctors[index];
        if (element.id != null && element.first_name != null) {
          if (element.first_name.toLowerCase().includes(this.searchString.toLowerCase()) || element.last_name.toLowerCase().includes(this.searchString.toLowerCase()) || element.phone_number.includes(this.searchString)) {
            arr.push(element)
          }
        }
      }
      this.recentlyDoctors = arr;
    }
    else if (this.searchString.length < 1) {
      this.allDoctorsList();
    }
  }

  // Close Doctor List Searching
  closeSearch() {
    this.checks = true;
    this.searchString = ''
    this.allDoctorsList();
    this.clearCount();
    this.selecteddoctorsnames = '';
    this.mulSelectedDoctor = [];
  }

  // To Get All Doctors List
  allDoctorsList() {
    let params = {
      id: this.userID,
    }
    this.adminService.adminGetAllDocs(params).subscribe((response:any) => {
      if (response) {
        console.log('Response', response);
        this.recentlyDoctors = [];
        this.doctorsList = response;
        response.map((ins:any) => {
          if (ins.id == this.DoctorId) {
            this.recentlyDoctors.push(ins)
          }
          else {
            this.recentlyDoctors.push(ins);
          }
        })
        console.log("docList", this.recentlyDoctors);
      }
      if (this.selectedCheckBox.length > 0) {
        for (let i = 0; i < this.recentlyDoctors.length; i++) {
          for (let j = 0; j < this.selectedCheckBox.length; j++) {
            if (this.recentlyDoctors[i].id == this.selectedCheckBox[j]) {
              this.recentlyDoctors[i]['checks'] = true;
            }
          }
        }
      }
    });
  }
  // To Clear Count Data
  clearCount() {
    if (this.selfDoctor) {
      // this.count_to_endtdate = new Date;
      // this.count_from_startdate = new Date;

      this.dateRangeCount(this.selfDoctorStartDate, this.selfDoctorEndDate, false);

    } else {
      this.patientCount = 0;
      this.prescriptionCount = 0;
      this.appointmentCount = 0;
      this.requisitionsCount = 0;
      this.reportCount = 0;
      this.medicalConditionCount=0
      this.healthAppCustomers = 0;
      this.healthAppPatients = 0;
      this.totalPatPendingVaccine = 0;
      this.healthAppNotInstalledCustomers = 0;
      this.totalPatTakenVaccine = 0;
      this.totalSendNotifications = 0;
      this.mulSelectedDoctor = [];
      this.count_to_endtdate = new Date;
      this.count_from_startdate = new Date;
      this.selecteddoctorsnames = '';
      this.searchString = ''
      this.selctedDoctPatPrescriptionsRecords=[]
      this.selctedDoctPatLabReportsRecords=[]
    this.selctedDoctPatRequisitionsRecords=[]
      this.allDoctorsList();
    }
  }
  reload() {
    window.location.reload();
  }

  // Count of Data from Date Range
  dateRangeCount(startDate:any, endDate:any, dateRangeWiseData:any) {
    if (this.selfDoctor && dateRangeWiseData == false) {
      this.dateRangeWiseData = false;
      let params = {
        doctorids: this.DoctorId,
        startDate: startDate,
        endDate: endDate,
      }
      this.adminService.admingetDoctorsStats(params).subscribe((response:any) => {
        if (response) {
          console.log('Response admingetDoctorsStats', response);
          this.doctorStatCount = response;
          this.patientCount = response.totalpatients[0].patcount;
          console.log("this.patientCount 2",this.patientCount);
          
          this.medicalConditionCount=this.doctorStatCount.totalMedicalConditions[0].AffectedComment
          this.appointmentCount = this.doctorStatCount.totalAppointments[0].totalAppointments;
          this.prescriptionCount = this.doctorStatCount.totalPrescriptions[0].totalPrescriptions;
          this.requisitionsCount = this.doctorStatCount.totalRequisitions[0].totalRequisitions;
          this.reportCount = this.doctorStatCount.totalReports.totalReports;
          this.healthAppCustomers = this.doctorStatCount.totalHealthCustomers[0].totalHealthCustomers;
          this.healthAppPatients = this.doctorStatCount.totalHealthPatients[0].totalHealthPatients;
          this.totDocHealtAppInstaCount = this.doctorStatCount.totDocHealtAppInstaCount;
          this.totalPatTakenVaccine = this.doctorStatCount.totalTakenVaccine[0].totalTakenVaccine;
          this.totalPatPendingVaccine = this.doctorStatCount.totalPendingVaccine[0].totalPendingVaccine;
          this.healthAppNotInstalledCustomers = this.doctorStatCount.totalHealthNotInstalledCustomers[0].totalHealthNotInstalledCustomers;
          this.totalSendNotifications = this.doctorStatCount.totalSendNotifications[0].totalSendNotifications;

        } else {
          console.log(response.error);
        }
      });
      params.doctorids = [this.DoctorId];
      this.adminService.getPendingVaccinesOn7thDay(params).subscribe((response:any) => {
        if (response) {
          this.totalPendingVaccineForSevenDays = response.totalPendingVaccineForSevenDays;
          this.totalPendingVaccineForSevenDaysLength = this.totalPendingVaccineForSevenDays.length
        }
      })
    } else if (this.selfDoctor && dateRangeWiseData == true) {
      this.dateRangeWiseData = true;
      this.startDate_pass = [startDate.year + '-' + startDate.month + '-' + startDate.day];
      this.endDate_pass = [endDate.year + '-' + endDate.month + '-' + endDate.day];
      let params = {
        doctorids: this.DoctorId,
        startDate: this.startDate_pass,
        endDate: this.endDate_pass,
      }
      this.adminService.admingetDoctorsStats(params).subscribe((response:any) => {
        if (response) {
          console.log('Response', response);
          this.doctorStatCount = response;
          this.patientCount = response.totalpatients[0].patcount;
          console.log("this.patientCount 3",this.patientCount);

          this.medicalConditionCount=this.doctorStatCount.totalMedicalConditions[0].AffectedComment
          this.appointmentCount = this.doctorStatCount.totalAppointments[0].totalAppointments;
          this.prescriptionCount = this.doctorStatCount.totalPrescriptions[0].totalPrescriptions;
          this.requisitionsCount = this.doctorStatCount.totalRequisitions[0].totalRequisitions;
          this.reportCount = this.doctorStatCount.totalReports.totalReports;
          this.healthAppCustomers = this.doctorStatCount.totalHealthCustomers[0].totalHealthCustomers;
          this.healthAppPatients = this.doctorStatCount.totalHealthPatients[0].totalHealthPatients;
          this.totDocHealtAppInstaCount = this.doctorStatCount.totDocHealtAppInstaCount;
          this.totalPatTakenVaccine = this.doctorStatCount.totalTakenVaccine[0].totalTakenVaccine;
          this.totalPatPendingVaccine = this.doctorStatCount.totalPendingVaccine[0].totalPendingVaccine;
          this.healthAppNotInstalledCustomers = this.doctorStatCount.totalHealthNotInstalledCustomers[0].totalHealthNotInstalledCustomers;
          this.totalSendNotifications = this.doctorStatCount.totalSendNotifications[0].totalSendNotifications;

        } else {
          console.log(response.error);
        }
      });
      params.doctorids = [this.DoctorId];
      this.adminService.getPendingVaccinesOn7thDay(params).subscribe((response:any) => {
        if (response) {
          this.totalPendingVaccineForSevenDays = response.totalPendingVaccineForSevenDays;
          this.totalPendingVaccineForSevenDaysLength = this.totalPendingVaccineForSevenDays.length
        }
      })
    } else {
      this.startDate_pass = [startDate.year + '-' + startDate.month + '-' + startDate.day];
      this.endDate_pass = [endDate.year + '-' + endDate.month + '-' + endDate.day];
      let params = {
        doctorids: this.mulSelectedDoctor,
        startDate: this.startDate_pass,
        endDate: this.endDate_pass,
      }
      this.adminService.admingetDoctorsStats(params).subscribe((response:any) => {
        if (response) {
          console.log('Response', response);
          this.doctorStatCount = response;
          this.patientCount = response.totalpatients[0].patcount;
          console.log("this.patientCount 4",this.patientCount);

          this.medicalConditionCount=this.doctorStatCount.totalMedicalConditions[0].AffectedComment
          this.appointmentCount = this.doctorStatCount.totalAppointments[0].totalAppointments;
          this.prescriptionCount = this.doctorStatCount.totalPrescriptions[0].totalPrescriptions;
          this.requisitionsCount = this.doctorStatCount.totalRequisitions[0].totalRequisitions;
          this.reportCount = this.doctorStatCount.totalReports.totalReports;
          this.healthAppCustomers = this.doctorStatCount.totalHealthCustomers[0].totalHealthCustomers;
          this.healthAppPatients = this.doctorStatCount.totalHealthPatients[0].totalHealthPatients;
          this.totDocHealtAppInstaCount = this.doctorStatCount.totDocHealtAppInstaCount;
          this.totalPatTakenVaccine = this.doctorStatCount.totalTakenVaccine[0].totalTakenVaccine;
          this.totalPatPendingVaccine = this.doctorStatCount.totalPendingVaccine[0].totalPendingVaccine;
          this.healthAppNotInstalledCustomers = this.doctorStatCount.totalHealthNotInstalledCustomers[0].totalHealthNotInstalledCustomers;
          this.totalSendNotifications = this.doctorStatCount.totalSendNotifications[0].totalSendNotifications;

        } else {
          console.log(response.error);
        }
      });

      params.doctorids = [this.DoctorId];
      this.adminService.getPendingVaccinesOn7thDay(params).subscribe((response:any) => {
        if (response) {
          this.totalPendingVaccineForSevenDays = response.totalPendingVaccineForSevenDays;
          this.totalPendingVaccineForSevenDaysLength = this.totalPendingVaccineForSevenDays.length
        }
      })
    }

  }
  onCheckboxChange(e:any, id:any, name:any, docObj:any) {

   if (e.target.checked) {
      this.doctorJoiningDate = moment(docObj.createdAt).format('DD-MM-YYYY');
    } else {
      this.doctorJoiningDate = ''
    }

    if (e.target.checked) {
      this.mulSelectedDoctor.push(id);
      this.selectedCheckBox = this.mulSelectedDoctor;
      console.log("select", this.selectedCheckBox);
      this.docRecordCount(this.mulSelectedDoctor);
      this.doctorPatLabReportsRecords();
      this.selecteddoctorsnames = this.selecteddoctorsnames + name + ", ";

    } else {
      this.mulSelectedDoctor.pop();
      console.log(this.mulSelectedDoctor);
      this.docRecordCount(this.mulSelectedDoctor);

      this.selecteddoctorsnames = this.selecteddoctorsnames.replace(name + ',', ' ');
      // this.selecteddoctorsnames = this.mulSelectedDoctor;
      console.log("rec", this.selecteddoctorsnames)
    }
    if (this.mulSelectedDoctor.length == 0) {
      this.clearCount();
    }
   
  }

  //To get MedicalConidtion
  getMedicalCondition(){
    if (this.selfDoctor && this.dateRangeWiseData == false) {
      let params = {
        doctorids: this.DoctorId,
        startDate: this.selfDoctorStartDate,
        endDate: this.selfDoctorEndDate,
      }
      console.log(params);
      this.adminService.admingetMedicalConditionsRecord(params).subscribe((response:any) => {
        if (response) {
          console.log('ResponseMarch9', response);
          this.selctedDoctPatientsRecords = response;
        }
      });
    } else if (this.selfDoctor || !this.selfDoctor && this.dateRangeWiseData == true) {
      let params = {
        doctorids: this.DoctorId,
        startDate: this.startDate_pass,
        endDate: this.endDate_pass,
      }
      console.log(params);
      this.adminService.admingetMedicalConditionsRecord(params).subscribe((response:any) => {
        if (response) {
          console.log('ResponseMarch9', response);
          this.selctedDoctPatientsRecords = response;
        }
      });
    } else {
      let params = {
        doctorids: this.mulSelectedDoctor,
        startDate: this.startDate_pass,
        endDate: this.endDate_pass,
      }
      console.log(params);
      this.adminService.admingetMedicalConditionsRecord(params).subscribe((response:any) => {
        if (response) {
          console.log('ResponseMarch9', response);
          this.selctedDoctPatientsRecords = response;
        }
      });
    }

  }

  // To get Doctor Patients Records
  doctorpatientsRecords() {
    if (this.selfDoctor && this.dateRangeWiseData == false) {
      let params = {
        doctorids: this.DoctorId,
        startDate: this.selfDoctorStartDate,
        endDate: this.selfDoctorEndDate,
      }
      console.log(params);
      this.adminService.admingetDoctorsPatientsRecords(params).subscribe((response:any) => {
        if (response) {
          console.log('Response', response);
          this.selctedDoctPatientsRecords = response;
        }
      });
    } else if (this.selfDoctor || !this.selfDoctor && this.dateRangeWiseData == true) {
      let params = {
        doctorids: this.DoctorId,
        startDate: this.startDate_pass,
        endDate: this.endDate_pass,
      }
      console.log(params);
      this.adminService.admingetDoctorsPatientsRecords(params).subscribe((response:any) => {
        if (response) {
          console.log('Response', response);
          this.selctedDoctPatientsRecords = response;
        }
      });
    } else {
      let params = {
        doctorids: this.mulSelectedDoctor,
        startDate: this.startDate_pass,
        endDate: this.endDate_pass,
      }
      console.log(params);
      this.adminService.admingetDoctorsPatientsRecords(params).subscribe((response:any) => {
        if (response) {
          console.log('Response', response);
          this.selctedDoctPatientsRecords = response;
          this.patientCount = response.totPatientRecords.length
          console.log("this.patientCount 5",this.patientCount);

          
        }
      });
    }
  }

  // To get Doctor Patients Prescriptions Records
  doctorPatPrescriptionsRecords() {
    if (this.selfDoctor && this.dateRangeWiseData == false) {
      let params = {
        doctorids: this.DoctorId,
        startDate: this.selfDoctorStartDate,
        endDate: this.selfDoctorEndDate,
      }
      console.log(params);
      this.adminService.admingetDoctPrescriptionsRecords(params).subscribe((response:any) => {
        if (response) {
          console.log('Response', response.totPatPrescriptionsRecords);
          response.totPatPrescriptionsRecords.map((ins:any)=>{
            if(ins.prescriptionPDFURL){
              this.selctedDoctPatPrescriptionsRecords.push(ins)
  
            }
           })
          console.log(this.selctedDoctPatPrescriptionsRecords);
        }
      });
    } else if (this.selfDoctor || !this.selfDoctor && this.dateRangeWiseData == true) {
      let params = {
        doctorids: this.DoctorId,
        startDate: this.startDate_pass,
        endDate: this.endDate_pass,
      }
      console.log(params);
      this.adminService.admingetDoctPrescriptionsRecords(params).subscribe((response:any) => {
        if (response) {
          console.log('Response', response.totPatPrescriptionsRecords);
          response.totPatPrescriptionsRecords.map((ins:any)=>{
            if(ins.prescriptionPDFURL){
              this.selctedDoctPatPrescriptionsRecords.push(ins)
  
            }
           })
          console.log(this.selctedDoctPatPrescriptionsRecords);
        }
      });
    } else {
      let params = {
        doctorids: this.mulSelectedDoctor,
        startDate: this.startDate_pass,
        endDate: this.endDate_pass,
      }
      console.log(params);
      this.adminService.admingetDoctPrescriptionsRecords(params).subscribe((response:any) => {
        if (response) {
          console.log('Response', response.totPatPrescriptionsRecords);
         // this.selctedDoctPatPrescriptionsRecords.push(response.totPatPrescriptionsRecords);
         response.totPatPrescriptionsRecords.map((ins:any)=>{
          if(ins.prescriptionPDFURL){
            this.selctedDoctPatPrescriptionsRecords.push(ins)

          }
         })
          console.log("May19",this.selctedDoctPatPrescriptionsRecords);
        }
      });
    }
  }

  openPdfUrl = (url:any) => {
    if (url) {
      window.open(url)
    }
  }
  

  // To get Doctor Patients Appointments Records
  doctorPatAppointmentsRecords() {
    if (this.selfDoctor && this.dateRangeWiseData == false) {
      let params = {
        doctorids: this.DoctorId,
        startDate: this.selfDoctorStartDate,
        endDate: this.selfDoctorEndDate,
      }
      console.log(params);
      this.adminService.admingetDoctPatAppointmentsRecords(params).subscribe((response:any) => {
        if (response) {
          console.log('Response', response.totAppointmentsRecords);
          this.selctedDoctPatAppointmentsRecords = response.totAppointmentsRecords;
          console.log(this.selctedDoctPatAppointmentsRecords);
        }
      });
    } else if (this.selfDoctor && this.dateRangeWiseData == true) {
      let params = {
        doctorids: this.DoctorId,
        startDate: this.startDate_pass,
        endDate: this.endDate_pass,
      }
      console.log(params);
      this.adminService.admingetDoctPatAppointmentsRecords(params).subscribe((response:any) => {
        if (response) {
          console.log('Response', response.totAppointmentsRecords);
          this.selctedDoctPatAppointmentsRecords = response.totAppointmentsRecords;
          console.log(this.selctedDoctPatAppointmentsRecords);
        }
      });
    } else {
      let params = {
        doctorids: this.mulSelectedDoctor,
        startDate: this.startDate_pass,
        endDate: this.endDate_pass,
      }
      console.log(params);
      this.adminService.admingetDoctPatAppointmentsRecords(params).subscribe((response:any) => {
        if (response) {
          console.log('Response', response.totAppointmentsRecords);
          this.selctedDoctPatAppointmentsRecords = response.totAppointmentsRecords;
          console.log(this.selctedDoctPatAppointmentsRecords);
        }
      });
    }
  }

  // To get Doctor Patients Requisitions Records
  doctorPatRequisitionsRecords() {
    if (this.selfDoctor && this.dateRangeWiseData == false) {
      let params = {
        doctorids: this.DoctorId,
        startDate: this.selfDoctorStartDate,
        endDate: this.selfDoctorEndDate,
      }
      console.log(params);
      this.adminService.admingetDoctPatRequisitionsRecords(params).subscribe((response:any) => {
        if (response) {
          response.totRequisitionsRecords.map((ins:any) => {
            if (!ins.sharedtolab) {
              ins.sharedtolab = { labName: "--" };
            } else {
              ins.sharedtolab = JSON.parse(ins.sharedtolab);
            }
          })
          response.totRequisitionsRecords.map((ins:any)=>{
            if(ins.url){
              this.selctedDoctPatRequisitionsRecords.push(ins)
            }
          })
        }
      });
    } else if (this.selfDoctor || !this.selfDoctor && this.dateRangeWiseData == true) {
      let params = {
        doctorids: this.DoctorId,
        startDate: this.startDate_pass,
        endDate: this.endDate_pass,
      }
      console.log(params);
      this.adminService.admingetDoctPatRequisitionsRecords(params).subscribe((response:any) => {
        if (response) {
          response.totRequisitionsRecords.map((ins:any) => {
            if (!ins.sharedtolab) {
              ins.sharedtolab = { labName: "--" };
            } else {
              ins.sharedtolab = JSON.parse(ins.sharedtolab);
            }
          })
          response.totRequisitionsRecords.map((ins:any)=>{
            if(ins.url){
              this.selctedDoctPatRequisitionsRecords.push(ins)
            }
          })
        }
      });
    } else {
      let params = {
        doctorids: this.mulSelectedDoctor,
        startDate: this.startDate_pass,
        endDate: this.endDate_pass,
      }
      console.log(params);
      this.adminService.admingetDoctPatRequisitionsRecords(params).subscribe((response:any) => {
        if (response) {
          response.totRequisitionsRecords.map((ins:any) => {
            if (!ins.sharedtolab) {
              ins.sharedtolab = { labName: "--" };
            } else {
              ins.sharedtolab = JSON.parse(ins.sharedtolab);
            }
          })

          response.totRequisitionsRecords.map((ins:any)=>{
            if(ins.url){
              this.selctedDoctPatRequisitionsRecords.push(ins)
            }
          })
          console.log("kkkkk",  this.selctedDoctPatRequisitionsRecords)

        }
      });
    }
  }

  // To get Doctor Patients Lab Reports Records
  doctorPatLabReportsRecords() {
    this.selctedDoctPatPrescriptionsRecords=[]
    this.selctedDoctPatLabReportsRecords=[]
    this.selctedDoctPatRequisitionsRecords=[]
 
    this.labReportCount=0
    let obj:any={}
    var data:any[] =[]
    if (this.selfDoctor && this.dateRangeWiseData == false) {
      let params = {
        doctorids: this.DoctorId,
        startDate: this.selfDoctorStartDate,
        endDate: this.selfDoctorEndDate,
      }
      console.log(params);
      this.adminService.admingetDoctPatLabReportsRecords(params).subscribe((response:any) => {
        if (response) {
          console.log('Response1', response.totLabReportsRecords);
          response.totLabReportsRecords.map((ins:any)=>{
            if(ins.documentType=="Lab Report"){
              this.selctedDoctPatLabReportsRecords.push(ins)
            }
            if(ins.documentType=="Prescription"){
           
              this.selctedDoctPatPrescriptionsRecords.push({"patName":ins.patName,
              "phone_number":ins.phone_number,"visit_date":ins.documentDate,"prescriptionPDFURL":ins.url
            })
            
          }
          if(ins.documentType=="Requisition"){
            this.selctedDoctPatRequisitionsRecords.push({"patName":ins.patName,
            "phone_number":ins.phone_number,"createdAt":ins.documentDate,"url":ins.url
          })
          }
            
           })
          console.log("lab2",this.selctedDoctPatLabReportsRecords);
          this.doctorPatPrescriptionsRecords();
          this.doctorPatRequisitionsRecords();
         // this.labReportCount=this.selctedDoctPatLabReportsRecords.length
        }
      });
    } else if (this.selfDoctor || !this.selfDoctor && this.dateRangeWiseData == true) {
      let params = {
        doctorids: this.DoctorId,
        startDate: this.startDate_pass,
        endDate: this.endDate_pass,
      }
      console.log(params);
      this.adminService.admingetDoctPatLabReportsRecords(params).subscribe((response:any) => {
        if (response) {
          console.log('Response2', response.totLabReportsRecords);
          response.totLabReportsRecords.map((ins:any)=>{
            if(ins.documentType=="Lab Report"){
              this.selctedDoctPatLabReportsRecords.push(ins)
  
            }
            if(ins.documentType=="Prescription"){
           
              this.selctedDoctPatPrescriptionsRecords.push({"patName":ins.patName,
              "phone_number":ins.phone_number,"visit_date":ins.documentDate,"prescriptionPDFURL":ins.url
            })
            
          }
          if(ins.documentType=="Requisition"){
            this.selctedDoctPatRequisitionsRecords.push({"patName":ins.patName,
            "phone_number":ins.phone_number,"createdAt":ins.documentDate,"url":ins.url
          })
          }

           })
          // this.labReportCount=this.selctedDoctPatLabReportsRecords.length
          console.log("lab1",this.selctedDoctPatLabReportsRecords);
          this.doctorPatPrescriptionsRecords();
         this.doctorPatRequisitionsRecords();
        }
      });
    } else {
      let params = {
        doctorids: this.mulSelectedDoctor,
        startDate: this.startDate_pass,
        endDate: this.endDate_pass,
      }
      console.log(params);
      this.adminService.admingetDoctPatLabReportsRecords(params).subscribe((response:any) => {
        if (response) {
          console.log('Response3', response.totLabReportsRecords);
         // this.selctedDoctPatLabReportsRecords = response.totLabReportsRecords;
         response.totLabReportsRecords.map((ins:any)=>{
          if(ins.documentType=="Lab Report"){
            this.selctedDoctPatLabReportsRecords.push(ins)

          }
          if(ins.documentType=="Prescription"){
           
              this.selctedDoctPatPrescriptionsRecords.push({"patName":ins.patName,
              "phone_number":ins.phone_number,"visit_date":ins.documentDate,"prescriptionPDFURL":ins.url
            })
            
          }
          if(ins.documentType=="Requisition"){
            this.selctedDoctPatRequisitionsRecords.push({"patName":ins.patName,
            "phone_number":ins.phone_number,"createdAt":ins.documentDate,"url":ins.url
          })
          }

         })
         //data.push(obj)
        
         console.log(data)
        
       //  console.log(data)
         //this.selctedDoctPatPrescriptionsRecords.push(data)
         console.log(this.selctedDoctPatPrescriptionsRecords)
         console.log(this.selctedDoctPatRequisitionsRecords)
          console.log(this.selctedDoctPatLabReportsRecords);
         this.doctorPatPrescriptionsRecords();
         this.doctorPatRequisitionsRecords();
        }
      });
     
    }
   // this.labReportCount=this.selctedDoctPatLabReportsRecords.length
  }

  // To get Doctor Customers Records
  doctorCustomersRecords() {
    if (this.selfDoctor && this.dateRangeWiseData == false) {
      let params = {
        doctorids: this.DoctorId,
        startDate: this.selfDoctorStartDate,
        endDate: this.selfDoctorEndDate,
      }
      console.log(params);
      this.adminService.admingetDocCustomersRecords(params).subscribe((response:any) => {
        if (response) {
          console.log('Response', response.totDocCustomersRecords);
          this.selctedDoctCostumersRecords = response.totDocCustomersRecords;
          console.log(this.selctedDoctCostumersRecords);
        }
      });
    } else if (this.selfDoctor || !this.selfDoctor && this.dateRangeWiseData == true) {
      let params = {
        doctorids: this.DoctorId,
        startDate: this.startDate_pass,
        endDate: this.endDate_pass,
      }
      console.log(params);
      this.adminService.admingetDocCustomersRecords(params).subscribe((response:any) => {
        if (response) {
          console.log('Response', response.totDocCustomersRecords);
          this.selctedDoctCostumersRecords = response.totDocCustomersRecords;
          console.log(this.selctedDoctCostumersRecords);
        }
      });
    } else {
      let params = {
        doctorids: this.mulSelectedDoctor,
        startDate: this.startDate_pass,
        endDate: this.endDate_pass,
      }
      console.log(params);
      this.adminService.admingetDocCustomersRecords(params).subscribe((response:any) => {
        if (response) {
          console.log('Response', response.totDocCustomersRecords);
          this.selctedDoctCostumersRecords = response.totDocCustomersRecords;
          console.log(this.selctedDoctCostumersRecords);
        }
      });
    }
  }

  // To get Health App Not Installed Records
  patHealthNotInstalledRecords() {
    if (this.selfDoctor && this.dateRangeWiseData == false) {
      let params = {
        doctorids: this.DoctorId,
        startDate: this.selfDoctorStartDate,
        endDate: this.selfDoctorEndDate,
      }
      console.log(params);
      this.adminService.admingetHealthNotInstalledRecords(params).subscribe((response:any) => {
        if (response) {
          console.log('Response', response.totHealthNotInstalledRecords);
          this.selctedDoctPatHealthNotInstalledRecords = response.totHealthNotInstalledRecords;
          console.log(this.selctedDoctPatHealthNotInstalledRecords);
        }
      });
    } else if (this.selfDoctor || !this.selfDoctor && this.dateRangeWiseData == true) {
      let params = {
        doctorids: this.DoctorId,
        startDate: this.startDate_pass,
        endDate: this.endDate_pass,
      }
      console.log(params);
      this.adminService.admingetHealthNotInstalledRecords(params).subscribe((response:any) => {
        if (response) {
          console.log('Response', response.totHealthNotInstalledRecords);
          this.selctedDoctPatHealthNotInstalledRecords = response.totHealthNotInstalledRecords;
          console.log(this.selctedDoctPatHealthNotInstalledRecords);
        }
      });
    } else {
      let params = {
        doctorids: this.mulSelectedDoctor,
        startDate: this.startDate_pass,
        endDate: this.endDate_pass,
      }
      console.log(params);
      this.adminService.admingetHealthNotInstalledRecords(params).subscribe((response:any) => {
        if (response) {
          console.log('Response', response.totHealthNotInstalledRecords);
          this.selctedDoctPatHealthNotInstalledRecords = response.totHealthNotInstalledRecords;
          console.log(this.selctedDoctPatHealthNotInstalledRecords);
        }
      });
    }
  }

  // To get Given Vaccination Records
  patGivenVaccRecords() {
    if (this.selfDoctor && this.dateRangeWiseData == false) {
      let params = {
        doctorids: this.DoctorId,
        startDate: this.selfDoctorStartDate,
        endDate: this.selfDoctorEndDate,
      }
      console.log(params);
      this.adminService.admingetPatGivenVaccRecords(params).subscribe((response:any) => {
        if (response) {
          console.log('Response admingetPatGivenVaccRecords 1', response.patGivenVaccRecords);
          this.selctedDoctPatGivaenVaccRecords = response.patGivenVaccRecords;
          console.log(this.selctedDoctPatGivaenVaccRecords);
        }
      });
    } else if (this.selfDoctor || !this.selfDoctor && this.dateRangeWiseData == true) {
      let params = {
        doctorids: this.DoctorId,
        startDate: this.startDate_pass,
        endDate: this.endDate_pass,
      }
      console.log(params);
      this.adminService.admingetPatGivenVaccRecords(params).subscribe((response:any) => {
        if (response) {
          console.log('Response admingetPatGivenVaccRecords2', response.patGivenVaccRecords);
          this.selctedDoctPatGivaenVaccRecords = response.patGivenVaccRecords;
          console.log(this.selctedDoctPatGivaenVaccRecords);
        }
      });
    } else {
      let params = {
        doctorids: this.mulSelectedDoctor,
        startDate: this.startDate_pass,
        endDate: this.endDate_pass,
      }
      console.log(params);
      this.adminService.admingetPatGivenVaccRecords(params).subscribe((response:any) => {
        if (response) {
          console.log('Response admingetPatGivenVaccRecords3', response.patGivenVaccRecords);
          this.selctedDoctPatGivaenVaccRecords = response.patGivenVaccRecords;
          console.log(this.selctedDoctPatGivaenVaccRecords);
        }
      });
    }
  }
  
  // To get Not Given Vaccination Records

  patNotGivenVaccRecords() {
    console.log("patNotGivenVaccRecords called");
    
    if (this.selfDoctor && this.dateRangeWiseData == false) {
      let params = {
        doctorids: this.DoctorId,
        startDate: this.selfDoctorStartDate,
        endDate: this.selfDoctorEndDate,
      }
      console.log(params);
      this.adminService.admingetPatNotGivenVaccRecords(params).subscribe((response:any) => {
        if (response) {
          console.log('ResponseMarc10', response.patNotGivenVaccRecords);
          this.selctedDoctPatNotGivaenVaccRecords = response.patNotGivenVaccRecords;
          console.log(this.selctedDoctPatNotGivaenVaccRecords);
        }
      });
    } else if (this.selfDoctor || !this.selfDoctor && this.dateRangeWiseData == true) {
      let params = {
        doctorids: this.DoctorId,
        startDate: this.startDate_pass,
        endDate: this.endDate_pass,
      }
      console.log(params);
      this.adminService.admingetPatNotGivenVaccRecords(params).subscribe((response:any) => {
        if (response) {
          console.log('Response', response.patNotGivenVaccRecords);
          this.selctedDoctPatNotGivaenVaccRecords = response.patNotGivenVaccRecords;
          console.log(this.selctedDoctPatNotGivaenVaccRecords);
        }
      });
    } else {
      let params = {
        doctorids: this.mulSelectedDoctor,
        startDate: this.startDate_pass,
        endDate: this.endDate_pass,
      }
      console.log(params);
      this.adminService.admingetPatNotGivenVaccRecords(params).subscribe((response:any) => {
        if (response) {
          console.log('Response', response.patNotGivenVaccRecords);
          this.selctedDoctPatNotGivaenVaccRecords = response.patNotGivenVaccRecords;

          console.log("selctedDoctPatNotGivaenVaccRecords",this.selctedDoctPatNotGivaenVaccRecords) ;
        }
      });
    }
  }

  // To get Not Given Vaccination Records
  patPushNotificationsRecords() {
    if (this.selfDoctor && this.dateRangeWiseData == false) {
      let params = {
        doctorids: this.DoctorId,
        startDate: this.selfDoctorStartDate,
        endDate: this.selfDoctorEndDate,
      }
      console.log(params);
      this.adminService.admingetPatPushNotificationsRecords(params).subscribe((response:any) => {
        if (response) {
          console.log('Response', response.patPushNotificationsRecords);
          this.selctedDoctPatPushNotificationsRecords = response.patPushNotificationsRecords;
          console.log(this.selctedDoctPatPushNotificationsRecords);
        }
      });
    } else if (this.selfDoctor || !this.selfDoctor && this.dateRangeWiseData == true) {
      let params = {
        doctorids: this.DoctorId,
        startDate: this.startDate_pass,
        endDate: this.endDate_pass,
      }
      console.log(params);
      this.adminService.admingetPatPushNotificationsRecords(params).subscribe((response:any) => {
        if (response) {
          console.log('Response', response.patPushNotificationsRecords);
          this.selctedDoctPatPushNotificationsRecords = response.patPushNotificationsRecords;
          console.log(this.selctedDoctPatPushNotificationsRecords);
        }
      });
    } else {
      let params = {
        doctorids: this.mulSelectedDoctor,
        startDate: this.startDate_pass,
        endDate: this.endDate_pass,
      }
      console.log(params);
      this.adminService.admingetPatPushNotificationsRecords(params).subscribe((response:any) => {
        if (response) {
          console.log('Response', response.patPushNotificationsRecords);
          this.selctedDoctPatPushNotificationsRecords = response.patPushNotificationsRecords;
          console.log(this.selctedDoctPatPushNotificationsRecords);
        }
      });
    }
  }

  // To get Sales team person wise doctor list
  salesTeamRecords() {
    let params = {
      // salesPersonName: salesPersonName,
    }
    console.log(params);
    // console.log(salesPersonName);
    this.adminService.salesTeamCategory(params).subscribe((response:any) => {
      if (response) {
        console.log('Response', response);
        this.filterSalesPersonName = response.salesPersonNames;
        console.log(this.filterSalesPersonName);
      }
    });
  }
  salesTeamCatDoctors(salesPersonName:any) {
    let params = {
      salesPersonName: salesPersonName,
    }
    console.log("params", salesPersonName);
    console.log(salesPersonName);
    this.adminService.salesTeamCategoryRecords(params).subscribe((response:any) => {
      if (response) {
        console.log('Response', response);
        this.filterDoctorName = response;
        console.log("Filter Sales", this.filterDoctorName);
        this.recentlyDoctors = this.filterDoctorName.salesTeamCatDoctors;
      }
    });
  }

  selectSalesPerson(salespersonid:any) {
    let params = {
      salesPersonId: salespersonid,
    }
    this.adminService.salesTeamCategoryRecords(params).subscribe((response:any) => {
      if (response) {
        console.log('Response', response);
        this.filterDoctorName = response;
        console.log("Filter Sales", this.filterDoctorName);
        this.recentlyDoctors = this.filterDoctorName.salesTeamCatDoctors;
      }
    });
  }

  /*name of the excel-file which will be downloaded. */
  DownloadExcel(filename:any) {
    console.log(filename);
    this.fileName = filename;
    this.exportexcel();
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('doctor-patients-records-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }
  open(content:any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: false, windowClass: 'my-class' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  copen(contents:any) {
    this.modalService.open(contents, { ariaLabelledBy: 'modal-basic-title', backdrop: false, windowClass: 'my-class' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getDoctorData(element:any) {
    console.log("doctorData", element)
    this.router.navigate([`/admin/docOnboarding`], { queryParams: { userID: element.UserId } });
  }

  openManagementReports() {
    this.router.navigate([`/admin/managementReports`], { queryParams: {} });
  }
  getStartDate(startDate:any){
    this.startDateAndEndDate=true
    
    console.log(startDate)
    this.selfDoctorStartDate=startDate
    // endDate: this.selfDoctorEndDate,

  }
  getEndDate(endDate:any){
    this.selfDoctorEndDate=endDate
  }

}