import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats, NativeDateAdapter } from '@angular/material/core';
import { DoctorService } from '../../services/doctor.service';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { MatDialog } from '@angular/material/dialog';

export class AppDateAdapter extends NativeDateAdapter {  format(date: Date, displayFormat: Object): string {    if (displayFormat === 'input') {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      let year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    return date.toDateString();
  }
}

export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: {
      year: 'numeric', month: 'long', day: 'numeric'
    },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};

@Component({
  selector: 'doc-bill-report',
  templateUrl: './doc-bill-report.component.html',
  styleUrls: ['./doc-bill-report.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ],
})

export class DocBillReportComponent implements OnInit {
 
    @Input() selfDoctor!: String;
    hoveredTransactionlog: any = null;
    ishide:boolean=true;
  fileName:any;
  totalRefund=0;
  selectedDoctorId:any
  docName=""
  docListFlag:boolean=false
  doctorObjOrg:any=[]
  doctorObj:any=[]
  searchPatientBill: string
  fullDataSource: any[] = [];
  fromDate!: any;
  fromDateFormatted!: string;
  
  toDate!: any;
  toDateFormatted!: string;
  totalBillAmount:any=0;
  selfDoctorId:any=null
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  displayedColumns: string[] = ['date','appointmentDate', 'patientName','mobile','method', 'status','transactionType', 'amount', 'details'];
  dataSource = new MatTableDataSource<any>([]); // Initialize with empty data
  paymentTransactions:any=[];
  
 
  constructor(public dialog: MatDialog,private adminService: AdminService,private doctorService: DoctorService,) { 
    this.setDefaultDates();
  }
  setDefaultDates(): void {
    const currentDate = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(currentDate.getDate() - 7);
  
    // Set Date Objects (for mat-datepicker)
    this.fromDate = sevenDaysAgo;
    this.toDate = currentDate;
  
    // Set Formatted Strings
    this.fromDateFormatted = moment(sevenDaysAgo).startOf('day').format("YYYY-MM-DD HH:mm:ss.SSS");
    this.toDateFormatted = moment(currentDate).endOf('day').format("YYYY-MM-DD HH:mm:ss.SSS");
  }
  
  formatDate(date: Date): string {
    return date.toISOString().split('T')[0]; // Format yyyy-MM-dd
  }
  openDialog(content:any) {
  
    this.dialog.open(content);
  }

  closeDialog() {
    this.dialog.closeAll()

  }
  

  ngOnInit(): void {
    var docuserData = JSON.parse(localStorage.getItem('docObj')?? 'null');

   

    if (this.selfDoctor == '1' && docuserData) {
      //get doc detials
      this.doctorService.getDetails({ user_id: docuserData.id }).subscribe((response:any) => {
        if (response.success) {
          this.selectedDoctorId = response.doctor.id;
          console.log("Mar1666",this.selectedDoctorId)
        }
      });
    }
    setTimeout(()=>{
      this.getPaymentTransactions();
    },500)
    if (this.selfDoctor=='1') {
      this.displayedColumns = this.displayedColumns.filter(col => col !== 'details');
    }

  }
  clearPaymentTransactions(){
    this.selectedDoctorId=""
    this.getPaymentTransactions();
  }
  onDropdownOpen(opened: boolean) {
    if (opened) {
      console.log("Dropdown opened, fetching transactions...");
      this.clearPaymentTransactions()
    }
  }
  getPaymentTransactions(){
    
   
   let payload= {
    fromDate : this.fromDateFormatted,
    toDate : this.toDateFormatted
    ,filterBy : "paymentTime",
    doctorId:this.selectedDoctorId ? this.selectedDoctorId:null

  }
  console.log("date",payload)
  this.adminService.getPaymentTransactions(payload).subscribe(res=>{
    if(res){
      this.doctorObj = [];
      console.log("Mar112025",res)
      this.paymentTransactions = new MatTableDataSource(res)
      this.doctorObjOrg=res
      if (this.doctorObjOrg.error !== 'No transactions found!') {
        this.doctorObj = [...new Map(this.doctorObjOrg.map((item: any) => [item.doctorName, item])).values()];
      }
      
     
    
     
      const totalPayment = res
      .filter((txn:any) => txn.transactionType === 'payment') // Only sum payments
      .reduce((acc:any, txn:any) => acc + (txn.amount || 0), 0);
      console.log("payment",totalPayment)
     
      const totalRefund = res
        .filter((txn:any) => txn.transactionType === 'refund') // Only sum refunds
        .reduce((acc:any, txn:any) => acc + (txn.amount || 0), 0);
        console.log("refund",totalRefund)
        this.totalRefund=totalRefund

        this.totalBillAmount = totalPayment - totalRefund;
        console.log("Net Total:", this.totalBillAmount);
       
         
    }
  })


  }


  onSearchPatientbill(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
  
    // Set custom filterPredicate to filter on specific fields
    this.paymentTransactions.filterPredicate = (data: any, filter: string) => {
      const filterLower = filter.trim().toLowerCase();
  
      // Filter based on the required fields
      return (
        data.patientName?.toLowerCase().includes(filterLower) ||
        data.patientNumber?.toString().includes(filterLower) ||
        data.method?.toLowerCase().includes(filterLower) ||
        data.transactionStatus?.toLowerCase().includes(filterLower) ||
        data.transactionType?.toLowerCase().includes(filterLower) 
        
      );
    };
  
    // Apply the filter to the data source
    this.paymentTransactions.filter = filterValue;
  
    // Ensure pagination works after filtering
    if (this.paymentTransactions.paginator) {
      this.paymentTransactions.paginator.firstPage();
    }
  }
  
  
  applyFilter(filterValue: string) {
    this.paymentTransactions.filter = filterValue;
  }
  onDropdownSelectDocName(value:any) {
    this.selectedDoctorId=""
    this.docName=value
    this.doctorObj.map((ins:any)=>{
      if(this.docName==ins.doctorName){
        this.selectedDoctorId=ins.DoctorId
      }

    })
   
    if(this.docListFlag){
      //this.getPaymentTransactions();
    }
  }
  reverseDate(date:any) {
    let d = new Date(date);
    return { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
  }
   parseDate(dateObject:any) {
      var date = new Date();
      date.setDate(dateObject.day);
      date.setMonth(dateObject.month - 1);
      date.setFullYear(dateObject.year);
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    }
     onDateChange(event:any) {
      if (event && event.value) {
        this.fromDate = event.value; // Store Date object
      this.fromDateFormatted = moment(event.value).startOf('day').format("YYYY-MM-DD HH:mm:ss.SSS");
    
        
      }
      
    
      }

      onDateChangeToDate(event:any){
        if (event && event.value) {
          if (event && event.value) {
            this.toDate = event.value; // Store Date object
            this.toDateFormatted = moment(event.value).endOf('day').format("YYYY-MM-DD HH:mm:ss.SSS");
          }
        }
      }

      DownloadExcel(filename:any) {
        console.log(filename);
        this.fileName = filename;
        this.exportexcel();
      }
    

      exportexcel(): void {
          /* table id is passed over here */
          let element = document.getElementById('doctor-patients-records-table');
          const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
      
          /* generate workbook and add the worksheet */
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      
          /* save to file */
          XLSX.writeFile(wb, this.fileName);
      
        }
        showLog(item:any){
         
this.hoveredTransactionlog=item.transactionLog

        }
      
}
