import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DoctorListComponent } from './users/doctor/doctor-list/doctor-list.component';
import { DoctorViewComponent } from './users/doctor/doctor-view/doctor-view.component';
import { LabEditComponent } from './site-settings/labs/lab-edit/lab-edit.component';
import { LabListComponent } from './site-settings/labs/lab-list/lab-list.component';
import { AdminAuthGuard } from '../../auth/adminAuth.guard';
import { PatientListComponent } from './users/patient/patient-list/patient-list.component';
import { CustomerListComponent } from './users/customer/customer-list/customer-list.component';
import { DoctorClinicsComponent } from './users/doctor/doctor-clinics/doctor-clinics.component';
import { DoctorLetterheadComponent } from './users/doctor/doctor-letterhead/doctor-letterhead.component';
import { DoctorAddComponent } from './users/doctor/doctor-add/doctor-add.component';
import { DoctorEditProfileComponent } from './users/doctor/doctor-edit-profile/doctor-edit-profile.component';
import { ActivityLogComponent } from './activity-log/activity-log.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ReportsComponent } from './reports/reports.component';
import { OtpsComponent } from './otps/otps.component';
import { DocRptDashComponent } from './doc-rpt-dash/doc-rpt-dash.component';
import { DoctorProfileManagementComponent } from './doctor-profile-management/doctor-profile-management.component';
import { ManagementReportsComponent } from './management-reports/management-reports.component';
import { MarketPlaceParComponent } from '../market-place/market-place-par/market-place-par.component';
import { ProductsComponent } from '../market-place/products/products.component';
import { DocBillReportComponent } from './doc-bill-report/doc-bill-report.component';



export const adminRoutes: Routes = [

  {
    path: 'users/doctors',
    component: DoctorListComponent,
    canActivate: [AdminAuthGuard],
  }, {
    path: 'users/doctors/view/:id',
    component: DoctorViewComponent,
    canActivate: [AdminAuthGuard]
  }, {
    path: 'users/doctors/add',
    component: DoctorAddComponent,
    canActivate: [AdminAuthGuard]
  }, {
    path: 'users/doctors/profile/:id',
    component: DoctorEditProfileComponent,
    canActivate: [AdminAuthGuard]
  }, {
    path: 'users/doctors/clinics/:id',
    component: DoctorClinicsComponent,
    canActivate: [AdminAuthGuard]
  }, {
    path: 'users/doctors/letterhead/:id',
    component: DoctorLetterheadComponent,
    canActivate: [AdminAuthGuard]
  }, {
    path: 'users/patients',
    component: PatientListComponent,
    canActivate: [AdminAuthGuard],
  }, {
    path: 'users/customers',
    component: CustomerListComponent,
    canActivate: [AdminAuthGuard],
  }, {
    path: 'settings/labs',
    component: LabListComponent,
    canActivate: [AdminAuthGuard]
  }, {
    path: 'settings/labs/add',
    component: LabEditComponent,
    canActivate: [AdminAuthGuard]
  }, {
    path: 'settings/labs/edit/:id',
    component: LabEditComponent,
    canActivate: [AdminAuthGuard]
  },
  {
    path: 'activities',
    component: ActivityLogComponent,
    canActivate: [AdminAuthGuard],
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [AdminAuthGuard],
  },
  {
    path: 'otps',
    component: OtpsComponent,
    canActivate: [AdminAuthGuard],
  },
  {
    path: 'docdash',
    component: DocRptDashComponent,
    canActivate: [AdminAuthGuard],
  },
  {
    path: 'docOnboarding',
    component: DoctorProfileManagementComponent,
    canActivate: [AdminAuthGuard],
  },
  {
    path: 'managementReports',
    component: ManagementReportsComponent,
    canActivate: [AdminAuthGuard],
  },
  {
    path: 'allDocBillReport',
    component: DocBillReportComponent,
    canActivate: [AdminAuthGuard],
  },
  {
    path: 'listedProducts',
    component: ProductsComponent,
    canActivate: [AdminAuthGuard],
  },
  {
    path: 'marketPlaceData',
    component: MarketPlaceParComponent,
    canActivate: [AdminAuthGuard],
  },
  
];
