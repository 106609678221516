import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MarketPlaceService } from '../../services/market-place.service';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { PatientService } from "../../services/patient.service";

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {


  totalOrders :any=[];
  orderTableColumns: string[] = ['userName','grandTotal' ,'noOfItems','date'];
  fromDate :any;
  toDate = new Date();
  endDate = new Date//moment(new Date).format("MM-DD-YYYY")
  openOrder: boolean=false;
  orderData = {};
  grandTotal = 0;
  isUserSelected = 0;
  userData :any[] = [];
  issearchNumberLengthIs10 = 1;
  searchNumber : any ='';
  selectedUser:any = {};
  masterProductsVariations: any = [];


  constructor(
    private marketPlaceServiceService: MarketPlaceService,
    private router : Router,
    private patientService : PatientService
  ) { 
    this.dateToYMD(this.fromDate);
    this.getAllOrders()
  }

  ngOnInit(): void {
    this.getAllMasterProductVariations()
    this.masterProductsVariations = [];

  }
  getAllMasterProductVariations() {
    this.marketPlaceServiceService.getAllMasterProductVariations()
      .subscribe(
        (response) => {
          console.log("Received product variations:", response);
          this.masterProductsVariations = Array.isArray(response) ? response : [];
        },
        (error) => {
          console.error("Error fetching product variations:", error);
          this.masterProductsVariations = [];
        }
      );
  }
  getAllOrders(){  
    /*********  to hide selected user and its list after search ***********/
    this.isUserSelected = 0;
    this.selectedUser = {};
    this.userData = [];
    this.searchNumber = '';
    /*******************************************************/
    let params = {
      fromDate : this.fromDate,
      toDate : this.toDate
    }
    if(this.openOrder){
      this.router.navigate(['/marketplace/orders'])
      this.openOrder = false;
    }else {
      this.marketPlaceServiceService.getAllOrders(params)
      .subscribe((response)=>{
        console.log("ORDERS*******",response)
        this.totalOrders = new MatTableDataSource(response);
      })
    }
  }



  dateToYMD(date:any) {
    date = new Date()
    var d = date.getDate();
    var m = date.getMonth(); //Month from 0 to 11
    var y = date.getFullYear();
    this.fromDate =  '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
  }

  // showOrderDetails(order : any){
  //   console.log("order clicke in order row", order);
    
  //   this.orderData = order;
  //   console.log("Order Data after clickikng",this.orderData)
  //   this.openOrder = true;

  // }
  showOrderDetails(order: any) {
    this.orderData = order;
    console.log("Order Data after clickikng",this.orderData)
    this.openOrder = true;
  }
  getProductVariation(variationId: number) {
    const found = this.masterProductsVariations.find(
      (product:any) => product.id === variationId
    );
    return found || null; // Return null if not found
  }
  closeOrderDetails(){

    this.openOrder = false;
    console.log("logggas " ,this.openOrder);
    

    
  }

  getUserByContactNumber(){
    if(this.searchNumber.length != 10){
      this.issearchNumberLengthIs10 = 0;
      return;
    }else{
      this.issearchNumberLengthIs10 = 1;
      this.patientService.getPatientsByFullNumberSearch(this.searchNumber)
      .subscribe((response:any) => {
      this.userData = response;
      if(this.userData.length>0){
        this.isUserSelected = 0;
      }
    });
    }
  }

  userSelected(user : any){
    this.isUserSelected = 1;
    this.selectedUser = user;
    let params = { userId : this.selectedUser["userId"] }
    this.marketPlaceServiceService.getOrdersOfUser(params)
      .subscribe((response)=>{
        console.log("ORDERS*******111",response)
        this.totalOrders = new MatTableDataSource(response);
      })
  }

}
