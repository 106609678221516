import { Component, OnInit } from '@angular/core';
import { MarketPlaceService } from '../../services/market-place.service'
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup, FormArray,FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss'],
})
export class InventoryComponent implements OnInit {

  constructor(
    private marketPlaceServiceService: MarketPlaceService,
    private router: Router,
    public dialog: MatDialog,
    private fb: FormBuilder
  ) {
    this.getAllMasterProductVariations()

    this.masterProductFormGroup = new FormGroup({
      // productName":"product 3",
      // "brandId" : 2,
      // "productDescription" :"productDescription for product 3",
      // "unitOfMeasurement": "piece",
      // "pricePerUnit":250,
      // "category":"test ,test1,test2"
      productName: new FormControl(''),
      productDescription: new FormControl(''),
      unitOfMeasurement: new FormControl(''),
      pricePerUnit: new FormControl(''),
      category: new FormControl(''),
    });

  }

  masterProductsVariations: any = [];
  displayedColumns: string[] = ['productName', 'price', 'company', 'measurement', 'quantity', 'update'];
  addProductFormOpen = false;
  quantity : any;
  editData: any;
  masterProductFormGroup : FormGroup;
  selectedProduct:any = { }
  // masterProductVarationsFormGroup : FormGroup;

  ngOnInit(): void {
  }

  getAllMasterProductVariations() {
    this.marketPlaceServiceService.getAllMasterProductVariations()
      .subscribe((response:any) => {
        console.log("response***************************", response)
        // this.dataSource = response;
        this.masterProductsVariations = new MatTableDataSource(response);
        // console.log("");
        
      });
  }

  addProduct() {
    this.addProductFormOpen = !this.addProductFormOpen
  }

  viewListedProducts() {
    this.router.navigate(['/marketplace/listedProduct'])
  }

  openModal(templateRef:any) {
    let dialogRef = this.dialog.open(templateRef, {
      //  width: '250px',
    });

    dialogRef.afterClosed().subscribe((result:any) => {
      console.log('The dialog was closed');
    });
  }

  openUpdateInventoryModal(templateRef:any,product:any){
    let dialogRef = this.dialog.open(templateRef, {});
    this.selectedProduct = product;
    // dialogRef.afterClosed().subscribe((result:any) => {
    //   console.log('The dialog was closed');
    // });
  }

  opendeleteProductVariationModal(templateRef:any,product:any){
    let dialogRef = this.dialog.open(templateRef, {});
    this.selectedProduct = product;
  }

  closeModal() {
    this.dialog.closeAll()
  }

  onSubmit() { 
    console.log(this.masterProductFormGroup.value)
    this.masterProductFormGroup.reset()
    this.dialog.closeAll()
  }

  editInventory(dt:any){
console.log("dttttt", dt);
this.editData = dt.quantity
    this.quantity = dt.quantity
    console.log("this.quantity",this.quantity);
    

  }

  increment() {
    console.log("this.selectedProduct ",this.quantity);
    this.quantity = this.quantity  + 1;

  }

  decrement() {
    if (this.quantity == 0) {
      this.quantity = 0
    }
    else {
      this.quantity = this.quantity - 1
    }
  }

  updateProductVariationQuantity(){
    var finalChange= this.quantity - this.editData 
    let req_vars = {
      quantity : finalChange,
      productVariationId : this.selectedProduct["id"]
    }
    console.log("updateProductVariationQuantity",req_vars);
    
    this.marketPlaceServiceService.updateProductVariationQuantity(req_vars).
    subscribe((response:any) => {
      console.log("response",response);
      
      this.quantity = this.quantity ;
      this.getAllMasterProductVariations()
      this.dialog.closeAll()
    })
  }

  deleteProductVariation(){
    console.log("product variaiton to delete with ID"+this.selectedProduct["id"]);
    let req_vars = {
      productVariationId : this.selectedProduct["id"]
    }
    this.marketPlaceServiceService.deleteProductVariation(req_vars).subscribe((response:any) => {
      this.getAllMasterProductVariations();
      this.dialog.closeAll()
    })
  }

}
